@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@300;400;500&display=swap');
/*
*/

:root {
  --clr-gunmetal: #121619;
  --clr-charcoal: #313c44;
  --clr-danger: #fe5f55;
  --clr-warning: #FFBE0B;
  --clr-success: #09814A;
  
  --clr-missed: #212121;
  --clr-found: var(--clr-warning);
  --clr-correct: var(--clr-success);
}

html {
  height: 100%;
}

body {
  background: var(--clr-gunmetal);
  font-family: 'Roboto', sans-serif;
  margin: 0;
  height: 100%;
}

h1 { margin: 0 }
#root {
  display: flex;
  flex-direction: column;
  height: 100svh;
  gap: .5rem;
}

.brand {
  color: var(--clr-warning);
  text-align: center;
  display: grid;
  gap: 0.25rem;
  padding:.5rem;
}

.brand__info {
  font-size: 1.25rem;
  color: white;
}

.grid {
  align-self: flex-start;
  margin-inline: auto;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  width: min(100% - 6rem, 25rem);
}

.grid__row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.grid__cell {
  aspect-ratio: 1;
  border: 1px solid var(--clr-charcoal);
  position: relative;
}

.letter {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  animation: pop ease-in-out 250ms;
  text-shadow: 0 0 .125em rgba(0, 0, 0, 0.55);
}

button[data-state="missed"] {
  background: var(--clr-missed) !important;
}
[data-state="found"] {
  background: var(--clr-found) !important;
}
[data-state="correct"] {
  background: var(--clr-correct) !important;
}

@keyframes pop {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.keyboard {
  user-select: none;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: .25rem;
  width: min(100% - .5rem, 45rem);
  margin-top: auto;
  margin-inline: auto;
  margin-bottom: 1rem;
}

.keyboard button {
  appearance: none;
  font-family: 'Roboto Mono', monospace;
  aspect-ratio: 1 / 1.35;
  place-items: center;
  border: 0;
  border-radius: .25em;
  color: white;
  background: var(--clr-charcoal);
  font-size: clamp(1.2em, 1.75em, 3vw);
  padding: 0;
  text-shadow: 0 0 .125em rgba(0, 0, 0, 0.55);
}

.del {
}
.keyboard button.enter, .keyboard button.share {
  aspect-ratio: unset;
  grid-column: span 2;
}

.share[data-hilight="true"] {
  box-shadow: inset 0 0 0 .125em var(--clr-success);
}

button[data-selected="true"] {
  background: var(--clr-danger);
}

.notification {
  display: grid;
  color: white;
  text-align: center;
  font-size: 1.25rem;
  place-self: center;
  padding: .5rem 1rem;
  border-radius: .25em;
  position: absolute;
  top:0;
  left:0;
  right:0;
}

.notification[data-type="error"] {
  background: var(--clr-danger);
}
.notification[data-type="success"] {
  background: var(--clr-success);
}

.game {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  gap: 1rem
}
